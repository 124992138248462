<template>
  <div>
    <el-dialog
      title="操作记录"
      width="1000px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-table border :data="tableData" header-cell-class-name="table_header">
        <el-table-column property="create_time" label="操作时间" align="center"></el-table-column>
        <el-table-column property="log_content" label="操作内容" align="center"></el-table-column>
        <el-table-column property="log_status" label="状态" align="center"></el-table-column>
        <el-table-column property="memo" label="备注" align="center"></el-table-column>
        <el-table-column property="create_user_name" label="操作人" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {logEntity} from '@/api/entity.js'
export default {
  name: "operating-record",
  data() {
    return {
      dialogFormVisible:false,
      tableData:[]
    };
  },
  methods: {
    async isShow(val) {
      this.dialogFormVisible = true
      const parme = {
        entity_id:val
      }
      const {code,data} = await logEntity(parme)
      if(code == 200){
        console.log(data)
      }
    },
    handleClose(done){
      done();
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .customW{
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title{
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  /deep/ .table_header{
    background-color: #f8f8f9 !important;
    color: #515a6e !important;
  }
}

</style>
