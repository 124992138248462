export const businesslCol = [
  {
    label: '公司名称',
    prop: 'entity_name',
    align: 'center'
  },
  // {
  //   label: '统一社会信用代码',
  //   prop: 'entity_number',
  //   align: 'center'
  // },
  {
    label: '联系人手机号',
    prop: 'contact_user_phone',
    align: 'center'
  },
  {
    label: '一级机构',
    prop: 'senior_name',
    align: 'center'
  },
  {
    label: '下属商家',
    slot: 'senior_business_num'
  },
  {
    label: '合同类型',
    slot: 'contract_type'
  },
  {
    label: '关联商务',
    prop: 'affiliated_user_name',
    align: 'center'
  },
  {
    label: '创建人',
    prop: 'create_user_name',
    align: 'center'
  },
  {
    label: '创建时间',
    prop: 'create_time',
    align: 'center'
  },
  {
    label: '更新时间',
    prop: 'update_time',
    align: 'center'
  },
  {
    label: '签约状态',
    slot:'sign_status'
  },
  {
    label: '签约错误原因',
    slot:'yee_entry_fail_reason'
  },
  {
    label: '分账类型',
    slot:'ledger_type'
  },
  {
    label: "操作",
    slot: 'operation'
  }
]


export default {
  businesslCol
}