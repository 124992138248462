<template>
  <div class="businessList">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="140px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="公司名称">
              <el-input
                v-model="searchForm.entity_name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统一社会信用代码">
              <el-input
                v-model="searchForm.entity_number"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="一级机构">
              <el-select
                style="width: 100%"
                v-model="searchForm.senior_id"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                @change="onSeniorChange"
                @clear="onSeniorClear"
                clearable
                filterable
                remote
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.senior_id"
                  :label="item.senior_name"
                  :value="item.senior_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select
                style="width: 100%"
                v-model="searchForm.sign_status"
                clearable
              >
                <el-option
                  v-for="item in signStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="联系人手机号">
              <el-input
                v-model="searchForm.contact_user_phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同类型">
              <el-select
                style="width: 100%"
                v-model="searchForm.contract_type"
                clearable
              >
                <el-option
                  v-for="item in contractTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="关联商务">
              <el-select
                style="width: 100%"
                v-model="searchForm.affiliated_user_name"
                clearable
              >
                <el-option
                  v-for="item in affiliatedUserList"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="创建人">
              <el-input
                v-model="searchForm.create_user_name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="更新时间">
              <el-date-picker
                style="width: 100%"
                v-model="update_time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="setDateRange"
                @change="onUpdateTimeChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否商户收款">
              <el-select
                style="width: 100%"
                v-model="searchForm.ledger_type"
                clearable
              >
                <el-option
                  v-for="item in ledgerType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="text-align: right">
              <el-button
                v-if="managerType != 0 || getAuth('entityOperate')"
                size="small"
                @click="createEntiy"
                >创建主体</el-button
              >
              <el-button class="ml_20" size="small" @click="onExport"
                >导出</el-button
              >
              <el-button type="primary" size="small" @click="serchData"
                >查询</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bastab">
      <base-table
        :columns="businesslCol"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="initData"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="senior_business_num"
          label="下属商家"
          align="center"
          width="90"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="scope.row.senior_business_num > 0"
              @click="skipBusiness(scope.row)"
              >{{ scope.row.senior_business_num }}</el-button
            >
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="contract_type"
          label="合同类型"
          align="center"
          width="90"
          :formatter="contractTypeFormatter"
        ></el-table-column>
        <el-table-column
          slot="sign_status"
          label="签约状态"
          align="center"
          width="100"
          :formatter="signStatusFormatter"
        ></el-table-column>
        <el-table-column
          slot="yee_entry_fail_reason"
          label="签约错误原因"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.sign_status == 1">{{
              scope.row.yee_entry_fail_reason
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="ledger_type"
          label="是否商户收款"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.ledger_type === 0 ? "否" : "是"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="operation"
          label="操作"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('checkEntity')"
              @click="detail(scope.row)"
              >详情</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="edit(scope.row)"
              v-if="managerType != 0 || getAuth('entityOperate')"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                (managerType != 0 || getAuth('entityOperate')) &&
                scope.row.sign_status == 0
              "
              @click="del(scope.row)"
              >删除</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click="operatingRecord(scope.row)"
              >操作记录</el-button
            > -->
            <el-button
              type="text"
              size="small"
              v-if="
                (managerType != 0 || getAuth('auditEntity')) &&
                scope.row.sign_status <= 1
              "
              @click="auditEntity(scope.row)"
              >签约</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                (managerType != 0 || getAuth('announceEntity')) &&
                scope.row.sign_status == 2
              "
              @click="announceEntity(scope.row)"
              >发布</el-button
            >
          </template>
        </el-table-column>
      </base-table>
      <div
        v-loading.fullscreen.lock="fullscreenLoading"
        :element-loading-text="textLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      ></div>
    </div>
    <operating-record ref="operatingRecord" />
  </div>
</template>

<script>
import columns from "./columns";
import operatingRecord from "./components/operating-record.vue";
import {
  signStatusList,
  contractTypeList,
  ledgerTypeList
} from "@/assets/equipment/allSelect.json";
import {
  listEntity,
  statusEntity,
  auditEntity,
  displayEntity,
} from "@/api/entity.js";
import { selectSenior, selectListAccount } from "@/api/selectfun.js";
import { getAuth } from "@/utils/index.js";
import config from "@/utils/config.js";
export default {
  name: "businessList",
  components: { operatingRecord },
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      signStatusList: signStatusList,
      contractTypeList: contractTypeList,
      affiliatedUserList: [],
      seniorList: [],
      seniorLoading: false,
      update_time: "",
      searchForm: {
        page: 1,
        per_page: 10,
      },
      tableData: [],
      total: 0,
      loading: false,
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      senior_id: "",
      fullscreenLoading: false,
      textLoading: "",
      ledgerType: ledgerTypeList
    };
  },

  mounted() {
    this.senior_id = this.$route.query.senior_id;
    if (this.senior_id) {
      this.searchForm.senior_id = Number(this.senior_id);
      this.seniorRemoteMethod(this.$route.query.senior_name);
    }
    this.initData();
    this.getSelectListAccount();
  },

  methods: {
    serchData() {
      this.searchForm.page = 1;
      this.searchForm.per_page = 10;
      this.initData();
    },
    async initData() {
      const { code, data } = await listEntity(this.searchForm);
      if (code == 200) {
        this.tableData = data.data;
        this.total = data.total;
      }
    },
    createEntiy() {
      this.$router.push("/business/createEntity");
    },
    onExport() {
      const url = `${
        config.BASE_URL
      }/export/businessEntityExport?token=${localStorage.getItem(
        "token"
      )}&entity_name=${this.searchForm.entity_name || ""}&entity_number=${
        this.searchForm.entity_number || ""
      }&contact_user_phone=${
        this.searchForm.contact_user_phone || ""
      }&sign_status=${this.searchForm.sign_status || ""}&create_user_name=${
        this.searchForm.create_user_name || ""
      }&affiliated_user_name=${
        this.searchForm.affiliated_user_name || ""
      }&update_time_start=${
        this.searchForm.update_time_start || ""
      }&update_time_end=${
        this.searchForm.update_time_end || ""
      }&contract_type=${this.searchForm.contract_type || ""}&senior_id=${
        this.searchForm.senior_id || ""
      }`;
      window.open(url, "_blank");
    },
    announceEntity(row) {
      let that = this;
      this.$confirm("是否发布当前主体？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          that.fullscreenLoading = true;
          that.textLoading = "主体发布中";
          const parmes = {
            entity_id: row.entity_id,
          };
          const { code } = await displayEntity(parmes);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "发布完成!",
            });
            that.initData();
            that.fullscreenLoading = false;
          } else {
            that.fullscreenLoading = false;
          }
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    auditEntity(row) {
      let that = this;
      this.$confirm("是否签约当前主体？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          that.fullscreenLoading = true;
          that.textLoading = "主体签约中";
          const parmes = {
            entity_id: row.entity_id,
          };
          // if(row.sign_status == 0){
          //   parmes = {
          //     entity_id: row.entity_id,
          //   };
          // } else if(row.sign_status == 1){
          //   parmes = {
          //     entity_id: row.entity_id,
          //     sign_status: 2
          //   };
          // }

          const { code } = await auditEntity(parmes);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "已提交!",
            });
            that.initData();
            that.fullscreenLoading = false;
          } else {
            that.fullscreenLoading = false;
          }
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    del(row) {
      this.$confirm("是否删除当前主体？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            entity_id: row.entity_id,
            status: 2,
          };
          const { code } = await statusEntity(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
    detail(row) {
      this.$router.push({
        path: "/business/entityInfo",
        query: {
          entity_id: row.entity_id,
        },
      });
    },
    edit(row) {
      this.$router.push({
        path: "/business/createEntity",
        query: {
          entity_id: row.entity_id,
        },
      });
    },
    operatingRecord(row) {
      this.$refs.operatingRecord.isShow(row.entity_id);
    },
    onUpdateTimeChange(val) {
      if (val) {
        this.searchForm.update_time_start = val[0] + " " + "00:00:00";
        this.searchForm.update_time_end = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.update_time_start = "";
        this.searchForm.update_time_end = "";
      }
    },
    onSeniorChange(val) {
      this.searchForm.senior_id = val;
      this.initData();
    },
    onSeniorClear() {
      this.searchForm.senior_id = "";
      this.seniorList = [];
      this.initData();
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    async getSelectSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data;
      }
    },
    skipBusiness(row) {
      this.$router.push({
        path: "/business/businessList",
        query: {
          entity_id: row.entity_id,
          entity_name: row.entity_name,
        },
      });
    },
    contractTypeFormatter(row) {
      switch (row.contract_type) {
        case 0:
          return "纸质合同";
        case 1:
          return "电子合同";
        default:
          return "其他";
      }
    },
    signStatusFormatter(row) {
      switch (row.sign_status) {
        case 0:
          return "未签约";
        case 1:
          return "平台已签约";
        case 2:
          return "易宝已签约";
        case 3:
          return "已发布";
        default:
          return "其他";
      }
    },
    async getSelectListAccount() {
      const param = {
        username: "",
      };
      const { code, data } = await selectListAccount(param);
      if (code == 200) {
        this.affiliatedUserList = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.businessList {
  .derive {
    display: flex;
    justify-content: flex-end;
  }
  .bastab {
    margin-top: 20px;
  }
}
</style>